<template>
    <div class="row rtl">
        <div class="col-md-12">
            <h4 class="c-grey-900">{{subject}}</h4>
            <h4 class="c-grey-900">{{new Date().toUTCString()}}</h4>
            <table
            id="dataTable"
            class="table"
            role="grid"
            aria-describedby="dataTable_info"
            style="width: 100%"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr role="row">
                <th
                  class="sorting_asc"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-sort="ascending"
                  aria-label="Name: activate to sort column descending"
                >
                  #
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Position: activate to sort column ascending"
                >
                  الوحدة
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 200px"
                  aria-label="Position: activate to sort column ascending"
                >
                  الدرس
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 200px"
                  aria-label="Start date: activate to sort column ascending"
                >
                 السؤال
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Start date: activate to sort column ascending"
                >
                الأجوبة
                </th>
              </tr>
            </thead>
            <tbody v-for="item in questions" :key="item.id">
              <tr role="row" class="odd">
                <td>
                  {{item.id }}
                </td>
                <td>
                  {{item.chapter.name }}
                </td>
                <td>
                  {{item.lesson.name }}
                </td>
                <td style="width: 200px">
                  {{item.text.body }}
                </td>
                <td style="width: 200px">
                    <div v-for="answer in item.answers" :key="answer.id">
                        <div v-if="answer.is_true == 1" style="font-weight:bold">{{answer.answer}}</div>
                        <div v-else class="alert alert-secondary" role="alert">{{answer.answer}}</div>
                    </div>
                </td>
                <td>
                    <input type="checkbox"/>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
    </div>

  </template>
  
  <script>
  export default {
    props: {
        questions: {type : Array},
        subject: {type : String}
    }

  }
  </script>
  
  <style>
  
  </style>
  