<template>
  <div class="row">
    <div class="col-md-12">
      <div class="bgc-white bd bdrs-3 p-20 mB-20">
        <h2 class="c-grey-900 mB-20" style="display: inline-block">
          {{classs}}
        </h2>
        <span style="border-right: 1px solid #ddd; margin: 0 10px"></span>
        <button class="btn btn-sm btn-success" @click="addSubject">
          <i class="ti-plus mL-5" style="color: #fff"></i>إضافة مادة
        </button>
        <div id="dataTable_wrapper" class="dataTables_wrapper">
          <form @submit="checkForm">
            <div class="form-group row pB-30 pT-30">
              <div class="col-sm-3">
                <input
                  type="number"
                  min="1"
                  class="form-control"
                  v-model="subjectId"
                  placeholder="رقم المادة"
                />
              </div>
              <div class="col-sm-3">
                <input
                  type="text"
                  class="form-control"
                  v-model="subjectName"
                  placeholder="إسم المادة"
                />
              </div>
              <div class="col-sm-2">
                <button
                  type="submit"
                  class="btn btn-primary mL-10"
                  :disabled="loading"
                >
                  {{ $t("search") }}
                </button>
              </div>
            </div>
          </form>
          <table
            id="dataTable"
            class="table table-bordered dataTable table-hover"
            role="grid"
            aria-describedby="dataTable_info"
            style="width: 100%"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr role="row">
                <th
                  class="sorting_asc"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-sort="ascending"
                  aria-label="Name: activate to sort column descending"
                >
                  #
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Position: activate to sort column ascending"
                >
                  {{ $t("name") }}
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Position: activate to sort column ascending"
                >
                  كتاب
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Start date: activate to sort column ascending"
                >
                 عدد الوحدات
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Start date: activate to sort column ascending"
                >
                 عدد الدروس
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Start date: activate to sort column ascending"
                >
                 عدد الأسئلة
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Start date: activate to sort column ascending"
                >
                 الامتحانات
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 30px"
                  aria-label="Start date: activate to sort column ascending"
                >
                  تعديل
                </th>

                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 60px"
                  aria-label="Start date: activate to sort column ascending"
                >
                  عرض الوحدات
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 60px"
                  aria-label="Start date: activate to sort column ascending"
                >
                  تصحيح المادة
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 60px"
                  aria-label="Start date: activate to sort column ascending"
                >
                  طباعة الأسئلة
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 60px"
                  aria-label="Start date: activate to sort column ascending"
                >
                  فديو
                </th>
              </tr>
            </thead>
            <tbody v-show="!loading" v-for="item in subjects" :key="item.id">
              <tr role="row" class="odd">
                <td class="sorting_1">{{ item.id }}</td>
                <td>
                   <img
                  width="30"
                  :src=" require('@/assets/static/images/subjects/'+item.pic)"
                  alt=""
                  />    {{item.ar_name }}
                </td>
                <td v-if="item.book == ''">
                  <a href="#" @click="editBook(item)" >لا يوجد كتاب</a>
                  </td>
                <td v-else>
                  <a href="javascript:"
                  @click="editBook(item)"
                    ><span class="badge rounded-pill bgc-amber-50 c-amber-700"
                      ><i class="ti-pencil"></i></span
                  ><div style="width: 20px;display: inline-block;"></div></a>
                  <a :href="'https://www.yalla-emtihan.com/yalla-emtihan/public/docs/books/'+item.book" target="_blank">عرض الكتاب</a>
                  </td>
                <td v-if="item.chapters_count > 0">
                  {{ item.chapters_count}} وحدة 
                </td>
                <td v-else class="disabled">
                  لا توجد أي وحدة 
                </td>
                <td v-if="item.lessons_count > 0">
                  {{ item.lessons_count}} درس 
                </td>
                <td v-else class="disabled">
                  لا يوجد أي درس 
                </td>
                <td v-if="item.questions_count > 0">
                  {{ item.questions_count}} سؤال 
                </td>
                <td v-else class="disabled">
                  لا توجد أي سؤال 
                </td>
                <td v-if="item.old_exams_count > 0">
                  <a href="#" @click="showExams(item)">{{ item.old_exams_count}} امتحان </a>
                </td>
                <td v-else class="disabled">
                  <a href="#" @click="showExams(item)">لا توجد أي امتحان </a>
                </td>
                <td>
                  <a href="javascript:"
                  @click="editSubject(item)"
                    ><span class="badge rounded-pill bgc-amber-50 c-amber-700"
                      ><i class="ti-pencil"></i></span
                  ></a>
                  </td>
                  <td>
                  <a href="javascript:"
                  @click="showChapter(item)"
                    ><span class="badge rounded-pill c-light-blue-500"
                      ><i class="ti-eye"></i></span
                  ></a>
                </td>
                <td>
                  <a href="javascript:"
                  @click="recapSubject(item)"
                    ><span class="badge rounded-pill c-light-blue-500"
                      ><i class=" ti-marker"></i></span
                  ></a>
                </td>
                <td v-if="item.isPrinting"><div style="font-size:5px;color:#9c27b0" class="spinner-border" role="status"></div></td>
                <td v-else>
                  <a href="javascript:"
                  @click="print(item)"
                  ><span class="badge rounded-pill bgc-purple-50 c-purple-700"
                      ><i class="ti-printer"></i></span
                  ></a>
                </td>
                <td>
                  <a href="javascript:"
                  @click="showVideos(item)"
                    ><span class="badge rounded-pill c-light-blue-500"
                      >{{ item.videos_count + ' فديو ' }}<i class=" ti-video-clapper"></i></span
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <loading v-show="loading" />
          <p v-show="!subjects.length" style="text-align: center">
            {{ $t("noData") }}
          </p>
          <div style="text-align:center;margin-top:40px">
          <pagination style="display:inline-block;text-align:center" v-model="page" :records="pagination.total ?pagination.total : 2 " :per-page="30" @paginate="getSubjects"/>
          </div>
        </div>
      </div>
    </div>
    <div id="pdf" hidden><PrintModel :questions="this.questions" :subject="this.subject"></PrintModel></div>
  </div>
</template>

<script>
import PrintModel from '../../../../components/PrintModel.vue';
export default {
    props: {
        id: {
            type: String,
            required: true
        },
        classs: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            subject: "",
            statusLoading: true,
            subjectName: "",
            questions: [],
            subjectId: "",
            subjects: [],
            pagination: {},
            page: 1,
        };
    },
    created() {
        this.getSubjects();
    },
    methods: {
        showChapter(subject) {
            this.$router.push("/chapters?id=" + subject.id + "&classs=" + this.classs + "-" + subject.ar_name);
        },
        showVideos(subject) {
            this.$router.push("/videos?id=" + subject.id + "&subject=" + this.classs + "-" + subject.ar_name);
        },
        recapSubject(subject) {
            this.$router.push("/recapSubject?id=" + subject.id + "&subject=" + subject.ar_name + " ("+this.classs+")");
        },
        showExams(subject){
          this.$router.push("/exams?id=" + subject.id + "&subject=" + this.classs + "-" + subject.ar_name);
        },
        checkForm: function (e) {
            e.preventDefault();
            this.getSubjects();
        },
        getSubjects: function () {
            this.loading = true;
            this.$http
                .get("https://yalla-emtihan.com/backend/subjects/getSubjects", {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("token"),
                },
                params: {
                    class_id: this.id,
                    subject_id: this.subjectId,
                    subject_name: this.subjectName,
                    page: this.page
                },
            })
                .then(function (data) {
                this.loading = false;
                switch (data.status) {
                    case 200:
                        this.subjects = data.body.data;
                        this.pagination = data.body;
                        console.log(this.pagination);
                        break;
                }
            }, (err) => {
                this.loading = false;
                switch (err.status) {
                    case 500:
                        this.error(this.$t("canNotAccess"));
                        break;
                    case 401:
                        this.error(this.$t("verificationProcessError"));
                        localStorage.clear();
                        sessionStorage.clear();
                        this.$router.push("/login");
                        break;
                    default:
                        this.error(this.$t("unexpectedError"));
                        break;
                }
            });
        },
        addSubject: function () {
            const panel1Handle = this.$showPanel({
                component: () => import("../../classes/subjects/AddSubjects.vue"),
                openOn: "right",
                width: 700,
                disableBgClick: true,
                props: {
                    reload: this.getSubjects,
                    classId: this.id
                },
            });
        },
        editBook(subject) {
          const panel1Handle = this.$showPanel({
                component: () => import("../../classes/subjects/EditBook.vue"),
                openOn: "right",
                width: 700,
                disableBgClick: true,
                props: {
                    subject: subject,
                    reload: this.getSubjects
                },
            });
            panel1Handle.promise.then((result) => { });
        },
        editSubject(tempCLass) {
            const panel1Handle = this.$showPanel({
                component: () => import("../../classes/AddClass.vue"),
                openOn: "right",
                width: 700,
                disableBgClick: true,
                props: {
                    levelId: this.id,
                    tempSubject: tempCLass,
                    reload: this.getSubjects
                },
            });
            panel1Handle.promise.then((result) => { });
        },
        print: function (item) {
          item.isPrinting = true;
          this.subject = item.ar_name+" ("+this.classs+")";
            this.$http
                .get("https://yalla-emtihan.com/backend/questions/print", {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("token"),
                },
                params: {
                  'subject_id' : item.id
                },
            })
                .then(function (data) {
                  item.isPrinting = false;
                switch (data.status) {
                    case 200:
                        this.questions = data.body.subject.questions;
                        var a = window.open("", "", "height=1000, width=1000");
                        a.document.write("<html>");
                        a.document.write('<head><meta charset="utf-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width,initial-scale=1.0"><link rel="preconnect" href="https://fonts.gstatic.com"><link href="https://fonts.googleapis.com/css2?family=Cairo&display=swap" rel="stylesheet"/><link href="https://code.jquery.com/ui/1.10.2/themes/smoothness/jquery-ui.css" rel="Stylesheet"/><link href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css" rel="stylesheet"/><style>*:not(i) , h1 , h2 , h3 , h4 , h5 , h6 {font-family: "Cairo", sans-serif !important;}.rtl {direction: rtl;}table, th, td {border: 1px solid black;text-align:center}</style></head>');
                        a.document.write("<body class='app'>");
                          var divContents = document.getElementById("pdf").innerHTML;
                        a.document.write(divContents);
                        a.document.write("</body></html>");
                        a.document.close();
                        a.print();
                        break;
                }
            }, (err) => {
              item.isPrinting = false;
                switch (err.status) {
                    case 500:
                        break;
                    case 401:
                        this.error(this.$t("verificationProcessError"));
                        localStorage.clear();
                        sessionStorage.clear();
                        this.$router.push("/login");
                        break;
                    default:
                        break;
                }
            });
        },
        error: function (message) {
            this.$toast.error(message, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
            });
        },
        warning: function (message) {
            this.$toast.warning(message, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
            });
        },
        success: function (message) {
            this.$toast.success(message, {
                position: "top-right",
                timeout: 3000,
                closeOnClick: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
            });
        },
    },
    components: { PrintModel }
};
</script>

<style>
</style>